import React from "react";
import { Container, Content } from "../../components";

import "./capabilities.scss";
import Development from "../../components/Development";
import { TopSelection } from "../../components/Cabilities";
import { OilService } from "../../components/Cabilities/Oil";
import { SofwareService } from "../../components/Cabilities/Software";

export default () => {
  return (
    <Container className="capabilities">
      <Content>
        <TopSelection />
        <OilService />
        <SofwareService />
      </Content>
    </Container>
  );
};

import React from "react";
import Content from "../Content";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import "./capabilities.scss";
import Development from "../Development";
import Container from "../Container";
import Tech from "../../assets/devices.png";
import ReactImg from "../../assets/react.png";
import AngularImg from "../../assets/angular.png";
import Flutter from "../../assets/flutter.png";
import NodeJS from "../../assets/nodejs.svg";
import Golang from "../../assets/golang.svg";

export const SofwareService = () => {
  return (
    <Element name="software">
      <Container className="software-container">
        <Content className="software-container-title">
          Software Technologies
        </Content>
        <Content className="software-container-description">
          Our main focus is to provide technical staffing solutions to software
          technology companies. The company sources and organizes talented
          software specialists with the relevant expertise to work on small and
          large scale software projects. Our software developers and engineers
          are well equipped and experienced in many areas of software
          development.
        </Content>
        <Content className="software-container-top-img">
          <img src={Tech} />
        </Content>
      </Container>
      <Container>
        <Content className="tools-description">
          Below are some of popular tools in development that being use by our
          developers
        </Content>
        <Content className="tools">
          <Content className="tools-item">
            <Content className="tools-item-img">
              <img src={ReactImg} />
            </Content>
            <Content className="tools-item-title">ReactJS/React-native</Content>
          </Content>
          <Content className="tools-item">
            <Content className="tools-item-img">
              <img src={AngularImg} />
            </Content>
            <Content className="tools-item-title">AngularJS</Content>
          </Content>
          <Content className="tools-item">
            <Content className="tools-item-img">
              <img src={Flutter} />
            </Content>
            <Content className="tools-item-title">Flutter</Content>
          </Content>
        </Content>
        <Content className="tools">
          <Content className="tools-item">
            <Content className="tools-item-img">
              <img src={NodeJS} />
            </Content>
            <Content className="tools-item-title">NodeJS</Content>
          </Content>
          <Content className="tools-item">
            <Content className="tools-item-img">
              <img src={Golang} />
            </Content>
            <Content className="tools-item-title">Golang</Content>
          </Content>
        </Content>
      </Container>
    </Element>
  );
};

import React from "react";
import Content from "../Content";

import "./container.scss";

export default ({ className, style, children }) => (
  <Content style={style} className={`container ${className || ""}`}>
    {children}
  </Content>
);

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, Executives, Development, About, Capabilities } from "./pages";
import Experience from "./pages/Experience";
import Contact from "./pages/Contact";

export default () => (
  <Switch>
    <Route path="/management">
      <Executives />
    </Route>
    <Route path="/capabilities">
      <Capabilities />
    </Route>
    <Route path="/experience">
      <Experience />
    </Route>
    <Route path="/contact-us">
      <Contact />
    </Route>
    <Route path="/about-us">
      <About />
    </Route>
    <Route path="/">
      <Home />
    </Route>
  </Switch>
);

/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Typist from "react-typist";
import { Container, Content } from "../../components";
import TopWave from "../../assets/top-wave.svg";
import BottomWave from "../../assets/bottom-wave.svg";
import { ExecutiveItem } from "../../components/Executives";

import ChrisImage from "./../../assets/chris.png";
import Duncan from "./../../assets/ross.png";
import IsraelImg from "./../../assets/israelcalderon.png";
import AliIMG from "./../../assets/ali.png";
import Ross from "./../../assets/duncan.png";
// import Dots from "./../../assets/dots.png";
import Footer from "../../components/Footer";

import "./executives.scss";
import { ShowPersonModal } from "../../components/Modals";

class Executives extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      payload: { actionStyle: {} },
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  handleClose() {
    this.setState({
      isVisible: !this.state.isVisible,
    });
  }

  handleAction(payload) {
    console.log(payload);
    this.handleClose();
    this.setState({ payload });
  }

  render() {
    return (
      <>
        <ShowPersonModal
          isVisible={this.state.isVisible}
          payload={this.state.payload}
          handleClose={this.handleClose}
        />
        <Container className="executives">
          <Container className="white">
            <Content className="executives-title">
              <Typist>Group Executives</Typist>
            </Content>

            <Content className="white executives-inner">
              <Content className="executives-inner-top-wave">
                <img className="executives-inner-top-wave-img" src={TopWave} />
              </Content>
              <Content className="executives-inner-body">
                {/* <Content>
                  <img alt="dots" src={Dots} />
                </Content> */}
                {/* <ExecutiveItem
                  name="Christopher Bailey"
                  //   description="Christopher Bailey possesses over 20 years of technical, commercial and management experience within
                  // the international resources sector.  Over the course of his career he has filled the following positions at
                  // international oil and gas operators and also service companies: Managing Director, Division Director,
                  // Operations Manager, Operations Superintendent and Design Engineer. "
                  description="Christopher Bailey possesses over 30 years of technical, commercial and management experience within
                the international resources sector.  Over the course of his career he has filled the following positions at
                international oil and gas operators and also service companies: Managing Director, Division Director,
                Operations Manager, Operations Superintendent and Design Engineer. The breadth of his experience
                includes activities in the oil and gas sector such as; managing offshore operations, commissioning of
                offshore oil field production facilities, negotiating service and material supply contracts, entering new
                markets, developing technical support resources, achieving financial and profitability targets and directing
                companies’ relations with government authorities. Christopher possesses a Bachelor of Science with
                Honours in Engineering Chemistry and also a Bachelor of Arts in Languages and Chemistry from Queen’s
                University at Canada."
                  src={ChrisImage}
                  role="General Manager"
                  handleAction={this.handleAction}
                  actionStyle={{
                    backgroundColor: "#3b8646",
                  }}
                /> */}
                {/* <ExecutiveItem
                  actionStyle={{
                    backgroundColor: "#05668D",
                  }}
                  name="Israel Calderon"
                  role="Executive Director – China and SE Asia"
                  //   description="Israel Calderon has an Engineering degree and studied Bachelor of Science in Software Engineering in the Philippines. He is a Certified Blockchain developer
                  // and having more than 5 years of experience in app development."
                  description="Israel Calderon possesses a Bachelor of Science specializing in Software Engineering from the
                Central Philippines University (CPU). He is a Certified Blockchain Software developer with
                more than 5 years of experience working on challenging projects for international companies."
                  src={IsraelImg}
                  handleAction={this.handleAction}
                /> */}
                <ExecutiveItem
                  actionStyle={{
                    backgroundColor: "#666A86",
                  }}
                  name="Ross Matthews"
                  role="Executive Director – UK and Europe"
                  //   description="Ross Matthews has over 15 years’ experience in the oil and gas industry working with international
                  // oil companies including Exxon Mobil and Apache Corporation. Specializing in Joint Venture
                  // Management Ross has experience in both upstream, midstream and downstream operations. With
                  // technical expertise in Production Chemistry, Metering, Product Allocation and Commercial Operations
                  // Ross has worked in the EU, ASIA and Middle East in various consulting roles. Ross possesses a Bachelor
                  // of Science from the Robert Gordon University."
                  description="Ross Matthews has over 15 years’ experience in the oil and gas industry working with international
                oil companies including Exxon Mobil and Apache Corporation. Specializing in Joint Venture
                Management Ross has experience in both upstream, midstream and downstream operations. With
                technical expertise in Production Chemistry, Metering, Product Allocation and Commercial Operations
                Ross has worked in the EU, ASIA and Middle East in various consulting roles. Ross possesses a Bachelor
                of Science from the Robert Gordon University."
                  handleAction={this.handleAction}
                  src={Ross}
                />
              </Content>
              <Content className="executives-inner-bottom-wave">
                <img
                  className="executives-inner-bottom-wave-img"
                  src={BottomWave}
                />
              </Content>
            </Content>
            <Content className="executives-bottom">
              <ExecutiveItem
                name="William Duncan"
                actionStyle={{
                  color: "black",
                  backgroundColor: "#E6EBE0",
                }}
                descriptionStyle={{
                  color: "black",
                }}
                src={Duncan}
                role="Executive Director – North and South America Region"
                //   description="William Duncan with over 25 years of experience in marketing and international business
                // development, William Duncan has been instrumental as the liaison for investors, partners and
                // clients alike. As a military man, William is no stranger to adversities and is especially skilled in
                // problem solving and efficient execution. "
                description="These challenges include penetrating and brokering deals
              in unestablished and foreign markets, raising capital in a challenging economy, international
              business development and expansion, procurement of specialized equipment, waste management,
              environmental remediation, and most importantly “Closing Deals”. His international business
              spanning across multiple continents like North America, Africa, Middle East, Latin America, Central
              America and Asia."
                handleAction={this.handleAction}
              />
            </Content>
            <Footer />
          </Container>
        </Container>
      </>
    );
  }
}

export default Executives;

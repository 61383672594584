/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";

import Content from "../Content";
import LogoGreen from "../../assets/logo-green.svg";
import NavItem from "./NavItem";
import "./navigator.scss";
import { useHistory } from "react-router-dom";

const Logo = () => {
  return (
    <Content className="logo">
      <img className="logo-png" src={LogoGreen} alt="profundo-logo" />
    </Content>
  );
};

const MobileNavigator = () => {
  const [isVisibleMenus, setVisible] = useState(false);
  const [activePath, setActivePath] = useState("Home");
  const history = useHistory();

  const setActive = (path) => {
    if (path) {
      setActivePath(path);
      setVisible(!isVisibleMenus);
      enablePageScroll();
    }
  };

  return (
    <>
      <Content className="mobile-navigator">
        <Content
          onClick={() => history.push("/")}
          className="mobile-navigator-holder"
        >
          <Logo />
          <Content
            onClick={() => {
              setVisible(!isVisibleMenus);

              if (isVisibleMenus) {
                enablePageScroll();
              } else {
                disablePageScroll();
              }
            }}
            className="mobile-navigator-holder-burger"
          >
            {isVisibleMenus ? (
              <img
                style={{ width: "100%", height: "100%" }}
                src={require("../../assets/close.svg")}
              />
            ) : (
              <img
                style={{ width: "100%", height: "100%" }}
                src={require("../../assets/menu-icon.svg")}
              />
            )}
          </Content>
        </Content>
        <Content className="mobile-navigator-holder-title">
          Petroleum Engineers and Operations Specialists
        </Content>
      </Content>
      {isVisibleMenus ? (
        <Content className="show-menus">
          <Content className="show-menus-mobile">
            <NavItem
              activePath={activePath}
              activeClassName="mobile-active"
              onClick={setActive}
              to="/"
              pathName="Home"
            />
            <NavItem
              activePath={activePath}
              activeClassName="mobile-active"
              onClick={setActive}
              to="/about-us"
              pathName="About"
            />
            <NavItem
              activePath={activePath}
              activeClassName="mobile-active"
              onClick={setActive}
              to="/management"
              pathName="Management"
            />
            <NavItem
              activePath={activePath}
              onClick={setActive}
              to="/capabilities"
              activeClassName="mobile-active"
              pathName="Capabilities"
            />
            <NavItem
              activePath={activePath}
              activeClassName="mobile-active"
              onClick={setActive}
              to="/experience"
              pathName="Experience"
            />
            <NavItem
              activePath={activePath}
              onClick={setActive}
              activeClassName="mobile-active"
              to="/contact-us"
              pathName="Contact Us"
            />
          </Content>
        </Content>
      ) : null}
    </>
  );
};

const DesktopNavigator = () => {
  const [activePath, setActivePath] = useState("Home");
  const history = useHistory();
  return (
    <Content className="desktop-navigator">
      <Content className="desktop-navigator-holder">
        <Content
          onClick={() => history.push("/")}
          className="desktop-navigator-holder-icon"
        >
          <Logo />
          <Content className="desktop-navigator-holder-icon-title">
            Petroleum Engineers and Operations Specialists
          </Content>
        </Content>
        <Content className="desktop-navigator-holder-links">
          <NavItem
            activePath={activePath}
            onClick={setActivePath}
            to="/"
            pathName="Home"
          />
          <NavItem
            activePath={activePath}
            onClick={setActivePath}
            to="/about-us"
            pathName="About"
          />
          <NavItem
            activePath={activePath}
            onClick={setActivePath}
            to="/management"
            pathName="Management"
          />
          <NavItem
            activePath={activePath}
            onClick={setActivePath}
            to="/capabilities"
            pathName="Capabilities"
          />
          <NavItem
            activePath={activePath}
            onClick={setActivePath}
            to="/experience"
            pathName="Experience"
          />
          <NavItem
            activePath={activePath}
            onClick={setActivePath}
            to="/contact-us"
            pathName="Contact Us"
          />
        </Content>
      </Content>
    </Content>
  );
};

export default () => {
  return (
    <>
      <Content className="navigator-holder">
        <MobileNavigator />
        <DesktopNavigator />
      </Content>
    </>
  );
};

/* eslint-disable jsx-a11y/no-access-key */
import React from "react";
import { NavLink } from "react-router-dom";

export default ({
  to = "",
  pathName = "",
  onClick,
  activePath = "Home",
  activeClassName = "",
}) => (
  <NavLink
    activeClassName={
      activeClassName
        ? activePath === pathName && activeClassName
        : activePath === pathName && "active-link"
    }
    className="link-style"
    to={to}
    onClick={() => onClick(pathName)}
    accessKey={pathName}
  >
    {pathName}
  </NavLink>
);

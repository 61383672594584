import React, { useEffect } from "react";
import Modal from "react-modal";
import Content from "../Content";

import "./modal.scss";
import { disablePageScroll, enablePageScroll } from "scroll-lock";

export const ShowPersonModal = ({ isVisible, handleClose, payload }) => {
  useEffect(() => {
    if (isVisible) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  }, [isVisible]);

  return (
    <Modal
      className="show-person"
      isOpen={isVisible}
      onRequestClose={handleClose}
      bodyOpenClassName="show-person-body"
    >
      <Content className="show-person-content">
        <Content
          className="show-person-content-head"
          style={payload.actionStyle}
        >
          <img
            src={payload.src}
            style={{ width: 200, height: 200 }}
            alt="person"
          />
          <Content className="show-person-content-head-name" style={{ color: payload.actionStyle.color}}>
            {payload.name}
          </Content>
          <Content className="show-person-content-head-role" style={{ color: payload.actionStyle.color}}>
            {payload.role}
          </Content>
        </Content>
        <Content className="show-person-content-body">
          <Content className="show-person-content-body-text">
            {payload.overallDescription}
          </Content>
        </Content>
      </Content>
    </Modal>
  );
};

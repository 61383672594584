import React from "react";
import Content from "../Content";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import FadeIn from "react-fade-in";
import Provide from "../../assets/provide2.png";
import Manage from "../../assets/manage2.png";
import i1 from "../../assets/i1.svg";
import i2 from "../../assets/i2.svg";
import i3 from "../../assets/i3.svg";
import i4 from "../../assets/i4.svg";
import i5 from "../../assets/i5.svg";
import i6 from "../../assets/i6.svg";
import i7 from "../../assets/i7.svg";
import i8 from "../../assets/i8.svg";
import i9 from "../../assets/i9.svg";
import i10 from "../../assets/i10.svg";
import i11 from "../../assets/i11.svg";
import i12 from "../../assets/i12.svg";
import i13 from "../../assets/i13.svg";

import "./capabilities.scss";

export const OilService = () => {
  return (
    <Element name="oil">
      <Content className="oil-service">
        <Content className="oil-service-title">
          Petroleum and Energy Services
        </Content>
        <Content className="oil-service-core">
          <Content className="oil-service-core-value1">
            <Content className="oil-service-core-value1-image">
              <img src={Provide} />
            </Content>
            <Content className="oil-service-core-value1-about">
              <Content className="oil-service-core-value1-about-main">
                Project Management and Engineering Services
              </Content>
              <Content className="oil-service-core-value1-about-notes">
                Profundo Technologies Limited specializes in providing project
                management services, reliable and experienced project or
                operations staff and completing niche engineering projects.
              </Content>
            </Content>
          </Content>
          <Content className="oil-service-core-value2">
            <Content className="oil-service-core-value2-about">
              <Content className="oil-service-core-value2-about-main">
                Professional and Operations Staffing
              </Content>
              <Content className="oil-service-core-value2-about-notes">
                Profundo Technologies; technical specialists excel at managing
                projects at all stages of development from early conceptual
                design to the commissioning phases and afterwards.
              </Content>
            </Content>
            <Content className="oil-service-core-value2-image">
              <img src={Manage} />
            </Content>
          </Content>
        </Content>
        <Content>
          <Content className="oil-service-list">
            <Content className="oil-service-list-desc">
              Our organization consisting of skilled engineers and operations
              specialists possesses a great depth of experience within the
              following specialized areas:
            </Content>
            <Content className="oil-service-list-holder">
              <Content className="oil-service-list-holder-icons">
                <img src={i1} />
                <img src={i2} />
                <img src={i3} />
                <img src={i4} />
                {/* </Content> */}
                {/* <Content className="oil-service-list-holder-icons"> */}
                <img src={i5} />
                <img src={i6} />
                <img src={i7} />
                <img src={i8} />
                {/* </Content> */}
                {/* <Content className="oil-service-list-holder-icons"> */}
                <img src={i10} />
                <img src={i11} />
                <img src={i12} />
                <img src={i13} />
                {/* </Content> */}
                {/* <Content className="oil-service-list-holder-icons"> */}
                <img src={i9} />
              </Content>
            </Content>
          </Content>
        </Content>
      </Content>
    </Element>
  );
};

import React from "react";
import { Container, Content } from "../../components";
// import ImageCarousel from "../../components/Carousels/ImageCarousel";
import VideoCarousel from "../../components/Carousels/VideoCarousel";
import Typist from "react-typist";

import "./home.scss";
import About from "../../components/About";
import Footer from "../../components/Footer";

export default () => {
  return (
    <Container className="home-css">
      <Content>
        <VideoCarousel/>
      </Content>
        <Footer />
    </Container>
  );
};

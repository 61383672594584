import React from "react";
import Content from "../Content";
import ReactPlayer from "react-player";

import "./experience.scss";

export default () => {
  return (
    <Content className="experience-component">
      <Content className="experience-component-video">
          <ReactPlayer
            className="experience-component-video-holder-player"
            url="https://vimeo.com/457472832"
            playing
            loop
          />
      </Content>
    </Content>
  );
};

import React from "react";
import { Container, Content } from "../../components";
import About from "../../components/About";
import Footer from "../../components/Footer";
import AboutImg from "../../assets/about.jpg";

import "./about.scss";

export default () => {
  return (
    <Container className="about-us-container">
      <Content className="about-us-container-part1">
        {/* <Content className="about-us-container-part1-title">
          <Content className="about-us-container-part1-title-greetings">
            Hello, Good Day!
          </Content>
          <Content className="about-us-container-part1-title-desc">
            We are Petroleum Engineers and Operations Specialist
          </Content>
        </Content> */}
        <Content className="about-us-container-part1-img">
          <img src={AboutImg} />
        </Content>
      </Content>
      <About />
    </Container>
  );
};

import React from "react";
import Container from "../Container";

import "./about.scss";
import Content from "../Content";
import About from "../../assets/about.svg";
import Footer from "../../components/Footer";

import GoTo from "../Buttons/GoTo";

export default () => (
  <>
    <Container className="about-container">
      <Content className="about-container-description">
        Profundo Technologies Limited was established in 2011 to provide
        engineering and operations support to international oil production
        companies operating in the Peoples Republic of China. Since its
        establishment the company has successfully completed multiple
        engineering and operations related projects in the oil and gas sector.
        The company has provided technical services supporting all phases of
        engineering, procurement and construction projects.
      </Content>
      <Content className="about-container-description">
        The company has broadened the scope of activities beyond the original
        oil and gas industry to providing services to additional energy sectors
        and also provides software design and engineering support to a number of
        technological enterprises.
      </Content>
      <Content className="about-container-description">
        Since the year 2013 the company has successfully completed projects at
        several international locations and now operates as a group of
        associated companies with registered and licensed entities at China,
        Qatar, Uganda, Germany and the United Kingdom.
      </Content>
    </Container>
      <Content>
        <Footer className="footer-about" />
      </Content>
    {/* <Container className="link-container"> */}
      {/* <Content className="link-container-description1">
        <Content className="link-container-description1-text">
          Profundo Technologies Limited operates in Qatar and Uganda through its
          wholly owned subsidiaries called “Avanti Energy Services Limited.”
        </Content>
        <GoTo
          onClick={() => window.open("https://avantiesl.com/")}
          className="link-container-description1-button"
          text="Go to Avanti"
        />
      </Content> */}
    {/* </Container> */}
  </>
);

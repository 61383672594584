import React from "react";
import Routes from "./Routes";
import { Navigator, Content } from "./components";
import ScrollToTop from "react-scroll-to-top";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.scss";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Router>
        <Navigator />
        <Routes />
      </Router>
        <ScrollToTop 
        smooth color="#3b8646" />
    </>
  );
}

export default App;

import React from "react";
import { Container, Content } from "../../components";
import VideoExperience from "../../components/Experience/videoTitle";

import "./experience.scss";
import Software from "../../components/Experience/software";
import Clients from "../../assets/clients3.png";
import Footer from "../../components/Footer"

export default () => {
  return (
    <>
      <Container className="experience-container">
        <Content className="experience-container-experience-title">Our Experience</Content>
        <Content className="experience-sub">
          <Content className="experience-sub-content">
            The Profundo Technologies oil, gas and energy team members have
            contributed to or managed projects at every major petroleum
            producing region in the world. Each of the company's technical
            specialists were staff members at a large oil and gas operators,
            service companies or project oriented organizations for several
            years or even decades prior to joining the company. They are
            familiar with first hand knowledge of the unique conditions of the
            industry at remote or developing locations.
          </Content>
          <VideoExperience />
        </Content>
        <Container className="experience-container-clients">
          <Content className="experience-container-clients-title">
            <Content className="experience-container-clients-title-header">
              Our Clients
            </Content>
          </Content>
          <Content className="experience-container-clients-img">
            <img src={Clients} />
          </Content>
        </Container>
        {/* <Container className="experience-container-software">
          <Software />
          <Content className="experience-container-software-title">
            Software Development
          </Content>
          <Content className="experience-container-software-description">
            Profundo Technologies can offer experienced Software Engineers and
            developers who develops multi platforms. The company can provide
            Blockchain developers, Fullstack Developers, Frontend Mobile
            Engineers (IOS and Android) platforms, Frontend and Backend Web
            developers, QA Engineers and more.
          </Content>
        </Container> */}
        <Footer />
      </Container>
    </>
  );
};

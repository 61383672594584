import React from "react";
import { Container, Content } from "../../components";
import Wave from "../../assets/contact-wave.svg";
import TopImg from "../../components/Contact/TopImg";

import "./contact.scss";
import Footer from "../../components/Footer";

export default () => {
  const contacts = [
    {
      name: "Jane Huang - China",
      number: "+86-13828750559",
      email: "jane@profundoqfz.com",
    },
    {
      name: "Ross Matthews – UK and Europe",
      number: "+44-7525-161309",
      email: "r.matthews@profundoqfz.com",
    },
    {
      name: "William Duncan – North American Region",
      number: "+1-403-923-3156",
      email: "william.duncan@profundoqfz.com",
    },
  ];

  return (
    <Container className="contact">
      <Content className="contact-col1" />
      <Content className="contact-col2">
        {contacts.map((item, index) => {
          return (
            <>
              <Content className="contact-col2-holder">
                <Content className="contact-col2-holder-name">
                  {item.name}
                </Content>
                <Content className="contact-col2-holder-number">
                  {item.number}
                </Content>
                <Content className="contact-col2-holder-email">
                  {item.email}
                </Content>
                {index + 1 === contacts.length && (
                  <Content
                    style={{
                      position: "absolute",
                      width: '100%',
                      bottom: 0
                    }}
                  >
                    <img src={Wave} alt="wave" />
                  </Content>
                )}
              </Content>
            </>
          );
        })}
      </Content>
    </Container>
  );
};

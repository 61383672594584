import React from "react";
import Content from "../Content";
import Oil from "../../assets/oil.svg";
import Tech from "../../assets/tech.svg";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import "./capabilities.scss";

export const TopSelection = () => {
  return (
    <Content className="top-selection">
      <Content className="top-selection-holder">
        <Content className="top-selection-holder-title">
          What Do We Deliver ?
        </Content>
        <Content className="top-selection-holder-notes">
          Profundo Technologies Limited provides services to two major
          industries which are the oil and energy and also software technologies
          sector.
        </Content>
      </Content>
      <Content className="top-selection-buttons">
        <Link
          to="oil"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className="top-selection-buttons-item"
        >
          <img src={Oil} />
          <Content>Petroleum and Energy Services</Content>
        </Link>
        <Link
          to="software"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className="top-selection-buttons-item"
        >
          <img src={Tech} />
          <Content>SOFTWARE TECHNOLOGIES</Content>
        </Link>
      </Content>
    </Content>
  );
};

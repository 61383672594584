import React, { createRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./carousel.scss";

import ReactPlayer from "react-player";
import { useState } from "react";

export default () => {
  const carouselref = createRef();
  const [index, setCurrentIndex] = useState(0);

  return (
    <Carousel
      // showIndicators={true}
      // // centerMode
      infiniteLoop
      ref={carouselref}
      showArrows={false}
      useKeyboardArrows
      showStatus={false}
      selectedItem={index}
      swipeable={false}
      children="carousel-container"
      width={"100vw"}
    >
      <ReactPlayer
        playing={index === 0}
        onEnded={() => setCurrentIndex(1)}
        className="carousel-container-item"
        url="https://vimeo.com/457480962"
      />
      <ReactPlayer
        playing={index === 1}
        className="carousel-container-item"
        onEnded={() => setCurrentIndex(0)}
        url="https://vimeo.com/457475351"
        volume
      />
    </Carousel>
  );
};

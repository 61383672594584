import React from "react";
import FadeIn from "react-fade-in";
import Content from "../Content";

import "./executives.scss";

import User from "./../../assets/user.svg";

export const ExecutiveName = (props) => {
  const {
    name,
    role,
    description,
    actionStyle,
    hideAction,
    descriptionStyle,
    handleAction,
  } = props;

  return (
    <Content className="executive-content">
      <Content className="executive-content-name" style={descriptionStyle}>
        {name}
      </Content>
      <Content className="executive-content-role" style={descriptionStyle}>
        {role}
      </Content>
      <Content
        className="executive-content-description"
        style={descriptionStyle}
      >
        {description}
      </Content>
      {/* {!hideAction && (
        <Content
          onClick={() => handleAction(props)}
          className="executive-content-action"
          style={actionStyle}
        >
          Show More
        </Content>
      )} */}
    </Content>
  );
};

export const ExecutiveItem = ({
  name,
  role,
  description,
  actionStyle,
  descriptionStyle,
  titleStyle,
  hideAction = false,
  handleAction,
  src = User,
  overallDescription,
}) => (
  <FadeIn>
    <Content className="executives-container">
      <Content className="executives-container-img">
        <img src={src} alt="user" style={{ width: 200 }} />
      </Content>
      <Content className="executives-container-content">
        <ExecutiveName
          name={name}
          actionStyle={actionStyle}
          descriptionStyle={descriptionStyle}
          description={description}
          role={role}
          titleStyle={titleStyle}
          hideAction={hideAction}
          src={src}
          overallDescription={overallDescription}
          handleAction={handleAction}
        />
      </Content>
    </Content>
  </FadeIn>
);
